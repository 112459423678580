import React from 'react';
import './css/app.scss';
import './css/tailwind.scss';

import twitter from "./img/twitter.svg";
import discord from "./img/discord.svg";
import logo from "./img/logo.svg";
import twitch from "./img/twitch.svg";
// import facebook from "./img/facebook_1024.png";

export default class App extends React.Component<any, any> {

    state = {
        amountMade: "---.---,--",
    }

    formatAmount(moneyMade: any) {
        let moneyMadeFormatted: string = `${moneyMade}`;

        let split = moneyMadeFormatted.split(".")

        return `${split[0]},${split[1]}`
    }

    componentDidMount() {
        fetch("/data/donations.json").then(response => response.json().then(data => {

                this.setState({amountMade: data.amount})

            }
        ))
    }

    render() {

        const {amountMade} = this.state;
        const emmaLink = "https://tiltify.com/@stream-pc/steun-emma-kinderziekenhuis";

        return (
            <div>
                <div className="text-white flex flex-col bg-black h-screen bg-image">
                    <div className={"flex justify-center lg:justify-start items-center w-full"}>
                        <img className={"h-32 rounded-full"} alt={"StreamerCupNL "} src={logo}/>
                    </div>
                    <div
                        className={"flex-1 flex flex-col lg:flex-row items-center justify-center"}>
                        <div className={"text-center flex p-4 flex-col mb-4 "}>
                            <div className={"border-b-2 border-white mb-4"}>
                                <h1 className={"titlefont font-bold text-5xl lg:text-6xl text-white"}>Binnenkort.</h1>
                                <h1 className={"font-light text-xs mb-2 text-white"}>Zie jij hier
                                    iets moois!</h1>
                            </div>
                            <div className={"font-bold text-md"}>
                                <p>Ondertussen hebben wij al</p>
                                <h2 className={"font-bold text-2xl"}>€ {amountMade}</h2>
                                <p>opgehaald voor het</p>
                                <p className={"mb-4"}>Emma kinderziekenhuis</p>

                                <a href={emmaLink}
                                   ref={"_blank _noreferrer"}
                                   rel={"_blank _noreferrer"}
                                   className={"shadow-lg font-light px-3 py-2 border border-red-700 rounded-lg bg-streamercup-red hover:bg-red-800"}>Doneer
                                    nu!</a>
                            </div>
                        </div>
                        <div className={"w-11/12 lg:w-96 pt-4 pl-4 pr-4 pb-6 text-center bg-streamercup-blue border-streamercup-darkblue border rounded-lg shadow-lg"}>
                            <div className={"border-b-2 border-white mb-4"}>
                                <h1 className={"titlefont font-bold text-2xl text-white"}>FIFA 2021</h1>
                                <h1 className={"titlefont font-bold text-2xl text-white"}>Toernooi</h1>
                            </div>
                            <p className={"text-wrap text-sm mb-4"}>
                                Wil jij mee doen met ons FIFA 2021 toernooi?
                                Super leuk, klik op de knop hieronder om je in te schrijven.
                            </p>
                            <a className={"shadow-lg font-light px-3 py-2 border border-red-700 rounded-lg bg-streamercup-red hover:bg-red-800"}
                               href={"https://forms.gle/9z8SrecxT79MeZsg6"}>Schrijf je in!</a>
                        </div>
                    </div>


                    <div className={"text-center lg:text-left lg:flex w-full"}>
                        <div className={"flex-1 w-full gray-600 py-3 px-4"}>
                            <p className={"font-light text-xs text-white"}>Volg ons op social media</p>
                            <div className={"flex justify-center lg:justify-start"}>
                                <a href={"https://www.twitch.tv/streamercupnl"} className={"font-bold text-white"}><img
                                    alt={"Twitch logo"} className={"h-8 w-8"} src={twitch}/></a>
                                <a href={"https://discord.gg/hCyucDr"} className={"font-bold text-white"}><img
                                    alt={"Discord logo"} className={"h-8 w-8"} src={discord}/></a>
                                {/*<a className={"font-bold text-center text-white"}><img className={"h-6 w-6"}*/}
                                {/*                                                           alt={"Facebook logo"} src={facebook}/></a>*/}
                                <a href={"https://twitter.com/streamercupnl"} className={"font-bold text-white"}><img
                                    alt={"Twitter logo"} className={"h-8 w-8"} src={twitter}/></a>

                            </div>
                        </div>

                        <div className={"text-gray-600 py-3 px-4"}>
                            <div className={"lg:text-right"}>
                                <div className={"flex text-white font-bold flex-col lg:flex-row"}>
                                    <p className={"pr-1"}>Medemogelijk gemaakt door</p>
                                    <p><a
                                        className={"underline"}
                                        href={"https://stream-pc.nl"}>Stream-PC</a> en <a className={"underline"}
                                                                                          href={"https://nxtgen.events"}>NXTGENEVENTS</a>
                                    </p>
                                </div>
                                <p className={"font-bold text-xs text-gray-700"}>Gebouwd door <a
                                    className={"underline"}
                                    href={"https://genericdevelopment.nl"}>GenericDevelopment</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


